import ApiBase from "./apiBase";
import ApiBaseV2 from "./apiBaseV2";

// API instance
const Api = new ApiBase("/system");
const ApiV2 = new ApiBaseV2("/member")

// 시스템 관리 > Banner
export const getBanner = async (params) => {
  return await Api.submit("get", `banners`, params);
};

// 시스템 관리 > Banner > 노출여부 수정
export const updateBannerYn = async (id, params) => {
  return await Api.submit("post", `banners/detail/setting/${id}`, params);
};

// 시스템 관리 > Banner > 등록
export const insertBanners = async (params) => {
  return await Api.submit("post", `banners`, params);
};

// 시스템 관리 > Banner > 상세
export const getBannerDetail = async (id) => {
  return await Api.submit("get", `banners/${id}`);
};

// 시스템 관리 > Banner > 수정
export const updateBanners = async (id, params) => {
  return await Api.submit("post", `banners/${id}`, params);
};

// 시스템 관리 > 관리자 관리
export const getAdmin = async (params) => {
  return await Api.submit("get", `admins`, params);
};

// 시스템 관리 > 관리자 관리 > 활성여부 수정
export const updateAdminYn = async (id, params) => {
  return await Api.submit("post", `admins/detail/setting/${id}`, params);
};

// 시스템 관리 > 관리자 관리 > 등록
export const insertAdmins = async (params) => {
  return await Api.submit("post", `admins`, params);
};

// 시스템 관리 > 관리자 관리 > 아이디 중복 체크
export const getAdminIdOverlap = async (id) => {
  return await Api.submit("get", `admins/overlap/${id}`);
};

// 시스템 관리 > 관리자 관리 > 상세
export const getAdminDetail = async (id) => {
  return await Api.submit("get", `admins/${id}`);
};

// 시스템 관리 > 관리자 관리 > 수정
export const updateAdmins = async (id, params) => {
  return await Api.submit("post", `admins/${id}`, params);
};

// 마이페이지 - 조회
export const getAdminUser = async () => {
  return await ApiV2.submit("post", `admin`);
};

// 마이페이지 - 수정
export const updateAdminUser = async (params) => {
  return await ApiV2.submit("patch", `admin`, params);
};
