import axios from "axios";
import envConfig from "../envConfig";
import store from "../redux/store";
import { delToken } from "../redux/token";
import { delUser } from "../redux/user";

class ApiBaseV2 {
  /**
   * The constructor of the BaseProxy.
   *
   * @param {string} endpoint   The endpoint being used.
   * @param {Object} parameters The parameters for the request.
   */
  constructor(endpoint, parameters = {}) {
    this.endpoint = endpoint;
    this.parameters = parameters;
    // axios.defaults.baseURL = envConfig.BASE_API_V2;
  }
  /**
   * Method used to set the query parameters.
   *
   * @param {Object} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  setParameters(parameters) {
    this.parameters = {};
    Object.keys(parameters).forEach((key) => {
      this.parameters[key] = parameters[key];
    });
    return this;
  }

  /**
   * Method used to set a single parameter.
   *
   * @param {string} parameter The given parameter.
   * @param {*} value The value to be set.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  setParameter(parameter, value) {
    this.parameters[parameter] = value;

    return this;
  }

  /**
   * Method used to remove all the parameters.
   *
   * @param {Array} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  removeParameters(parameters) {
    parameters.forEach((parameter) => {
      delete this.parameters[parameter];
    });

    return this;
  }

  /**
   * Method used to remove a single parameter.
   *
   * @param {string} parameter The given parameter.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  removeParameter(parameter) {
    delete this.parameters[parameter];

    return this;
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {Object|null} data        The data to be send with the request.
   *
   * @returns {Promise} The result in a promise.
   */
  async submit(requestType, url, data = null) {
    const state = store.getState();

    if (state.token.token) {
      axios.defaults.baseURL = envConfig.BASE_API_V2;
      axios.defaults.headers.common.Authorization = `Bearer ${state.token.token}`;
    }
    return await new Promise((resolve, reject) => {
      if (requestType.toLowerCase() == "delete") {
        data = {
          data: data,
        };
      }
      let apiUrl = this.endpoint;
      if (url !== "") {
        apiUrl += `/${url}`;
      }

      if (requestType == "get" && data) {
        this.setParameters(data);
        apiUrl += `${this.getParameterString()}`;
      }

      axios[requestType](apiUrl, data)
          .then((response) => {
            const { code, data, message } = response.data;
            const custom = {
              code: code,
              msg: message,
              data: data,
            };

            // 로그인 일때 헤더 정보를 토큰으로 설정
            // if (
            //   ['/user/login', '/user/token/refresh'].includes(
            //     `${this.endpoint}/${url}`
            //   )
            // ) {
            //   custom.data = {
            //     token: response.headers.authorization,
            //     refresh: response.headers.refresh,
            //   }
            // }

            // 리프레쉬 토큰 테스트용
            // if (['/user/mypage'].includes(`${this.endpoint}/${url}`)) {
            //   throw {
            //     response: {
            //       status: 401,
            //       data: {},
            //     },
            //   }
            // }

            // else {
            //   throw {
            //     response: {
            //       status: 401,
            //       data: {}
            //     }
            //   }
            // }
            // console.log('resolve 1', url, response)
            return resolve(custom);
          })
          .catch(async ({ response }) => {
            const { code, data, message } = response.data;
            const custom = {
              code: code,
              msg: message,
              data: data,
            };
            if (response) {
              // 토큰 정보 없을 때 로그아웃
              if (response.status == "401") {
                if (url == "login") {
                  return resolve(custom);
                } else {
                  store.dispatch(delToken());
                  store.dispatch(delUser());
                  return resolve(custom);
                }
              } else {
                return resolve(custom);
              }
            } else {
              return reject();
            }
          });
    });
  }

  /**
   * Method used to fetch all items from the API.
   *
   * @returns {Promise} The result in a promise.
   */
  all() {
    return this.submit("get", `/${this.endpoint}`);
  }

  /**
   * Method used to fetch a single item from the API.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  find(id) {
    return this.submit("get", `/${this.endpoint}/${id}`);
  }

  /**
   * Method used to create an item.
   *
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  create(item) {
    return this.submit("post", `/${this.endpoint}`, item);
  }

  /**
   * Method used to update an item.
   *
   * @param {int}    id   The given identifier.
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  update(id, item) {
    return this.submit("put", `/${this.endpoint}/${id}`, item);
  }

  /**
   * Method used to destroy an item.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  destroy(id) {
    return this.submit("delete", `/${this.endpoint}/${id}`);
  }

  /**
   * Method used to transform a parameters object to a parameters string.
   *
   * @returns {string} The parameter string.
   */
  getParameterString() {
    const keys = Object.keys(this.parameters);

    const parameterStrings = keys
        .filter((key) => {
          return this.parameters[key] !== undefined &&
          this.parameters[key] !== null
              ? true
              : false;
        })
        // .filter((key) => !!this.parameters[key])
        .map((key) => `${key}=${this.parameters[key]}`);

    return parameterStrings.length === 0
        ? ""
        : `?${parameterStrings.join("&")}`;
  }
}

export default ApiBaseV2;
