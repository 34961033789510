import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Container } from 'reactstrap'
import { useNavigate, Link } from 'react-router-dom'
import { getCountryCodes } from '../../../api/common'
import { setLoading } from '../../../redux/loading'
import { store, useAppDispatch } from '../../../redux/store'
import {getAdminUser, updateAdminUser} from "../../../api/system";

const BannerManagement = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [adminuser, setAdminUser] = useState({})

  const [name, setName] = useState('')
  const [nickname, setNickname] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRe, setPasswordRe] = useState('')
  const [createdAt, setCreatedAt] = useState('')

  useEffect(async () => {
    dispatch(setLoading(false))

    // 국가코드 호출
    dispatch(setLoading(true))

    const { code, data } = await getCountryCodes().finally(() => {
      dispatch(setLoading(false))
    })

    await callAdminUser();
  }, [])

  // region 관리자 정보 조회 테스트
  const callAdminUser = async () => {
    dispatch(setLoading(true))

    // 정상작동 확인
    const { code, data } = await getAdminUser().finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      setAdminUser({...data})
      setName(data?.name)
      setNickname(data?.nickname)
      setEmail(data?.email)
      // setPhoneNumber(data.phoneNumber)
      setPassword(data.password)
      setCreatedAt(data?.createdAt)
    }
  }

  const update = async () => {
    // validation Check

    const params = {
      name,
      nickname,
      email,
      phoneNumber,
    }

    dispatch(setLoading(true))

    const { code } = await updateAdminUser(params).finally(() => {
      dispatch(setLoading(false))
    })
  }
  // endregion

  return (
      <Fragment>
        <Container>
          <div className="contents">
            <div className="card">
              <div className="card-head">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-center">
                    <h6 className="table-title">관리자 정보</h6>
                  </div>
                  <div className="d-flex align-center">
                    <h6 className="table-title">{store.getState().user.userName}</h6>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table mb-10">
                  <div className="table-head" border="0"></div>
                  <div className="table-body">
                    <table border="0" cellSpacing="0" cellPadding="0">
                      <colgroup>
                        <col width="15%"/>
                        <col width="35%"/>
                        <col width="15%"/>
                        <col width="35%"/>
                      </colgroup>
                      <tbody>
                      <tr>
                        <td className="tac bg">이름</td>
                        <td className="tac" colSpan="3">
                          <div className="d-flex" style={{alignItems: "center"}}>
                            <div className="me-10">
                              <input type="text" id="name" className="int" placeholder="이름" value={name} readOnly={true} disabled={true}/>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">닉네임 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <div className="d-flex" style={{alignItems: "center"}}>
                            <div className="me-10">
                              <input type="text" id="nickname" className="int" placeholder="닉네임" value={nickname}
                                     onChange={(e) => {
                                       setName(e.target.value)
                                     }}/>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">비밀번호 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <input type="password" id="password" className="int" placeholder="비밀번호" value={password}
                                 onChange={(e) => {
                                   setPassword(e.target.value)
                                 }}/>
                        </td>
                        <td className="tac bg">비밀번호 확인 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac">
                          <input type="password" id="passwordRe" className="int" placeholder="비밀번호 확인" value={passwordRe}
                                 onChange={(e) => {
                                   setPasswordRe(e.target.value)
                                 }}/>
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">이메일 <span className="required"><span>필수입력</span></span></td>
                        <td className="tac" colSpan="3">
                          <input type="text" id="email" className="int" placeholder="이메일" value={email}
                                 onChange={(e) => {
                                   setEmail(e.target.value)
                                 }}/>
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">전화번호</td>
                        <td className="tac" colSpan="3">
                          <div className="d-flex">
                            <input type="number" id="sTel" className="int me-10" value={phoneNumber} style={{width: '256px'}}
                                   onChange={(e) => {
                                     setPhoneNumber(e.target.value)
                                   }}/>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="tac bg">계정등록일</td>
                        <td className="tac">{createdAt}</td>
                        <td className="tac bg">계정상태</td>
                        <td className="tac"></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="form-btn justify-content-center mt-10">
                <div className="me-10">
                  <button type="submit" className="comm-btn bd-radius-5 btn-danger" >취소
                  </button>
                </div>
                <div>
                  <button type="submit" className="comm-btn bd-radius-5 btn-gray" onClick={() => update()}>저장
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fragment>
  )
}

export default BannerManagement
