import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Container } from 'reactstrap'
import { useNavigate, Link } from 'react-router-dom'
import { getInternshipStoryDetail, insertInternshipStory, updateInternshipStory } from '../../../api/post'
import { getUrlParams, isEmpty, setDateFormat } from '../../../utils/index'
import { setLoading } from '../../../redux/loading'
import { store, useAppDispatch } from '../../../redux/store'

const InternshipStory = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const navigate = useNavigate()

  /** New Version Internship Story Vaildation */
  const [story, setStory] = useState({
    title: '',
    quote: '',
    subTitle: '',
    summary: '',
    chapter1SubTitle: '',
    chapter1Content: '',
    chapter1Image: [],
    chapter1AddImage: [],
    chapter1ImageQuote: '',
    chapter1ImageFileName: '',
    chapter1ImagePreview: '',
    chapter1RemoveFiles: [],

    chapter2SubTitle: '',
    chapter2Content: '',
    chapter2Image: [],
    chapter2AddImage: [],
    chapter2ImageQuote: '',
    chapter2ImageFileName: '',
    chapter2ImagePreview: '',
    chapter2RemoveFiles: [],

    chapter3SubTitle: '',
    chapter3Content: '',
    chapter3Image: [],
    chapter3AddImage: [],
    chapter3ImageQuote: '',
    chapter3ImageFileName: '',
    chapter3ImagePreview: '',
    chapter3RemoveFiles: [],

    adminId: '',
    bestYn: 0,
    activeYn: 1,
    companyName: '',
    createdAt: '',
    updatedAt: '',
    link: '',
    mainImage: [],
    mainAddImage: [],
    mainImageFileName: '',
    mainImagePreview: '',
    mainRemoveFiles: [],

    bestImage: [],
    bestAddImage: [],
    bestImageFileName: '',
    bestImagePreview: '',
    bestRemoveFiles: [],

    writerName: store.getState()?.user?.username,
    awsS3Url: ''
  })
  const saveExceptPreview = (files, type, fileName) => {
    if(files !== null && files?.size > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(files)
      reader.onloadend = () => {
        setStory({ ...story, [`${type}AddImage`]: files, [`${type}ImagePreview`]: reader.result, [`${type}RemoveFiles`]: story?.[`${type}Image`].length > 0 ? [ ...story?.[`${type}Image`] ] : [], [`${type}ImageFileName`]: fileName })
      }
    } else {
      setStory({ ...story, [`${type}AddImage`]: [], [`${type}ImagePreview`]: '', [`${type}RemoveFiles`]: story?.[`${type}Image`].length > 0 ? [ ...story?.[`${type}Image`] ] : [], [`${type}ImageFileName`]: '' })
    }
  }

  const savePreview = (files, index) => {
    if(files !== null && files?.size > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(files)
      reader.onloadend = () => {
        setStory({ ...story, [`chapter${index}AddImage`]: files, [`chapter${index}ImagePreview`]: reader.result, [`chapter${index}RemoveFiles`]: story?.[`chapter${index}Image`].length > 0 ? [ ...story?.[`chapter${index}Image`] ] : [] })
      }
    } else {
      setStory({ ...story, [`chapter${index}ImagePreview`]: '', [`chapter${index}AddImage`]: [], [`chapter${index}RemoveFiles`]: story?.[`chapter${index}Image`].length > 0 ? [ ...story?.[`chapter${index}Image`] ] : [] })
    }
  }

  useEffect(async () => {
    dispatch(setLoading(false))
    
    const urlParams = getUrlParams(window.location.href)
    setParams(urlParams)
    
    if(urlParams?.mode != 'reg') {
      await getContents(urlParams)
      setEditMode(false)
    }else {
      setEditMode(true)
    }
  }, [])

  const cancelContents = () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm(`작성한 내용을 저장하지 않고\n상위 페이지로 이동하시겠습니까?`)) {
      window.location.reload()
    }
  }

  const dataCheck = () => {
    if(isEmpty(story?.title) 
      || isEmpty(story?.quote)
      || isEmpty(story?.subTitle)
      || isEmpty(story?.summary)
      || isEmpty(story?.chapter1SubTitle)
      || isEmpty(story?.chapter1Content)
      || isEmpty(story?.chapter1ImageQuote)
      || (!(story?.chapter1AddImage !== null && story?.chapter1AddImage?.size > 0) && story?.chapter1AddImage?.files.length === 0)
      // || (!(story?.mainAddImage !== null && story?.mainAddImage?.size > 0) && story?.mainAddImage?.files.length === 0)
      // || (!(story?.bestAddImage !== null && story?.bestAddImage?.size > 0) && story?.bestAddImage?.files.length === 0)
      ) {
      alert('필수 입력 항목을 입력해주세요')
      return false
    }
    return true
  }

  // 등록
  const insertContents = async () => {
    if(!dataCheck()) return

    const formData = new FormData()
    formData.append('title', story?.title)
    formData.append('quote', story?.quote)
    formData.append('subTitle', story?.subTitle)
    formData.append('summary', story?.summary)
    formData.append('chapter1SubTitle', story?.chapter1SubTitle)
    formData.append('chapter1Content', story?.chapter1Content)
    formData.append('chapter1AddImage', story?.chapter1AddImage)
    formData.append('chapter1ImageQuote', story?.chapter1ImageQuote)
    
    formData.append('bestYn', story?.bestYn)
    formData.append('activeYn', story?.activeYn)
    formData.append('companyName', story?.companyName)
    formData.append('link', story?.link)

    for(let i=2;i<=3; i++) {
      if(!isEmpty(story?.[`chapter${i}SubTitle`])) {
        formData.append(`chapter${i}SubTitle`, story?.[`chapter${i}SubTitle`])
      }
      if(!isEmpty(story?.[`chapter${i}Content`])) {
        formData.append(`chapter${i}Content`, story?.[`chapter${i}Content`])
      }
      if(story[`chapter${i}AddImage`] !== null && story[`chapter${i}AddImage`]?.size > 0){
        formData.append(`chapter${i}AddImage`, story?.[`chapter${i}AddImage`])
      }
      if(!isEmpty(story?.[`chapter${i}ImageQuote`])) {
        formData.append(`chapter${i}ImageQuote`, story?.[`chapter${i}ImageQuote`])
      }
    }
    if(story[`mainAddImage`] !== null && story[`mainAddImage`]?.size > 0){
      formData.append(`mainAddImage`, story?.[`mainAddImage`])
    }
    if(story[`bestAddImage`] !== null && story[`bestAddImage`]?.size > 0){
      formData.append(`bestAddImage`, story?.[`bestAddImage`])
    }
    
    dispatch(setLoading(true))
    
    const { code } = await insertInternshipStory(formData).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      alert('등록 완료')
      navigate('/contents/internship/story')
    }else {
      alert('등록 실패')
    }
  }

  // 상세
  const getContents = async (param) => {
    if(Object.keys(param?.sid).length > 0) {
      dispatch(setLoading(true))

      const { code, data } = await getInternshipStoryDetail(param?.sid).finally(() => {
        dispatch(setLoading(false))
      })

      if (code == 200) {
        const fileSet = {}
        if(!isEmpty(data?.mainImageId)) {
          fileSet[`mainImagePreview`] = `${data?.awsS3Url}/${data?.mainImage?.[0]?.url}`
          fileSet[`mainImageFileName`] = `${data?.mainImage?.[0]?.originalFileName ?? ''}`
        }

        if(!isEmpty(data?.bestImageId)) {
          fileSet[`bestImagePreview`] = `${data?.awsS3Url}/${data?.bestImage?.[0]?.url}`
          fileSet[`bestImageFileName`] = `${data?.bestImage?.[0]?.originalFileName ?? ''}`
        }

        if(!isEmpty(data?.chapter1ImageId)) {
          fileSet[`chapter1ImagePreview`] = `${data?.awsS3Url}/${data?.chapter1Image?.[0]?.url}`
        }
        if(!isEmpty(data?.chapter2ImageId)) {
          fileSet[`chapter2ImagePreview`] = `${data?.awsS3Url}/${data?.chapter2Image?.[0]?.url}`
        }
        if(!isEmpty(data?.chapter3ImageId)) {
          fileSet[`chapter3ImagePreview`] = `${data?.awsS3Url}/${data?.chapter3Image?.[0]?.url}`
        }

        setStory({ ...story, ...data, ...fileSet })
      } else {
        setStory({})
      }
    }
  }

  // 수정
  const updateContents = async () => {    
    if(!dataCheck()) return
    
    const formData = new FormData()
    formData.append('title', story?.title)
    formData.append('quote', story?.quote)
    formData.append('subTitle', story?.subTitle)
    formData.append('summary', story?.summary)

    formData.append('chapter1SubTitle', story?.chapter1SubTitle)
    formData.append('chapter1Content', story?.chapter1Content)
    formData.append('chapter1ImageQuote', story?.chapter1ImageQuote)
    
    formData.append('chapter2SubTitle', story?.chapter2SubTitle)
    formData.append('chapter2Content', story?.chapter2Content)
    formData.append('chapter2ImageQuote', story?.chapter2ImageQuote)

    formData.append('chapter3SubTitle', story?.chapter3SubTitle)
    formData.append('chapter3Content', story?.chapter3Content)
    formData.append('chapter3ImageQuote', story?.chapter3ImageQuote)

    formData.append('bestYn', story?.bestYn)
    formData.append('activeYn', story?.activeYn)
    formData.append('companyName', story?.companyName)
    formData.append('link', story?.link)

    // Chapter 이미지 정리 start
    if(story?.chapter1AddImage !== null && story?.chapter1AddImage?.size > 0) {
      formData.append('chapter1AddImage', story?.chapter1AddImage)
    }
    if(story?.chapter1RemoveFiles !== null && story?.chapter1RemoveFiles.length > 0) {
      const key = Object.keys(story?.chapter1RemoveFiles[0])
      for(let i=0; i<key.length; i++) {
        if(key[i] !== 'multipartFile') {
          formData.append(`chapter1RemoveFiles[0].${key[i]}`, story?.chapter1RemoveFiles[0][key[i]])
        }
      }
    }

    if(story?.chapter2AddImage !== null && story?.chapter2AddImage?.size > 0) {
      formData.append('chapter2AddImage', story?.chapter2AddImage)
    }
    if(story?.chapter2RemoveFiles !== null && story?.chapter2RemoveFiles.length > 0) {
      const key = Object.keys(story?.chapter2RemoveFiles[0])
      for(let i=0; i<key.length; i++) {
        if(key[i] !== 'multipartFile') {
          formData.append(`chapter2RemoveFiles[0].${key[i]}`, story?.chapter2RemoveFiles[0][key[i]])
        }
      }
    }

    if(story?.chapter3AddImage !== null && story?.chapter3AddImage?.size > 0) {
      formData.append('chapter3AddImage', story?.chapter3AddImage)      
    }
    if(story?.chapter3RemoveFiles !== null && story?.chapter3RemoveFiles.length > 0) {
      const key = Object.keys(story?.chapter3RemoveFiles[0])
      for(let i=0; i<key.length; i++) {
        if(key[i] !== 'multipartFile') {
          formData.append(`chapter3RemoveFiles[0].${key[i]}`, story?.chapter3RemoveFiles[0][key[i]])
        }
      }
    }
    // Chapter 이미지 정리 end

    // main, best 이미지 정리 start
    if(story?.mainAddImage !== null && story?.mainAddImage?.size > 0) {
      formData.append('mainAddImage', story?.mainAddImage)
    }
    if(story?.mainRemoveFiles !== null && story?.mainRemoveFiles.length > 0) {
      const key = Object.keys(story?.mainRemoveFiles[0])
      for(let i=0; i<key.length; i++) {
        if(key[i] !== 'multipartFile') {
          formData.append(`mainRemoveFiles[0].${key[i]}`, story?.mainRemoveFiles[0][key[i]])
        }
      }
    }

    if(story?.bestAddImage !== null && story?.bestAddImage?.size > 0) {
      formData.append('bestAddImage', story?.bestAddImage)
    }
    if(story?.bestRemoveFiles !== null && story?.bestRemoveFiles.length > 0) {
      const key = Object.keys(story?.bestRemoveFiles[0])
      for(let i=0; i<key.length; i++) {
        if(key[i] !== 'multipartFile') {
          formData.append(`bestRemoveFiles[0].${key[i]}`, story?.bestRemoveFiles[0][key[i]])
        }
      }
    }

    // main, best 이미지 정리 end
    dispatch(setLoading(true))

    const { code } = await updateInternshipStory(params?.sid, formData).finally(() => {
      dispatch(setLoading(false))
    })

    if (code == 200) {
      alert('수정 완료')
    }else {
      alert('수정 실패')
    }

    // 리로드
    window.location.reload()
  }

  return (
    <Fragment>
      <Container>
        <div className="contents">
          <div className="card">
            <div className="card-head">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-center"><h6 className="card-title">{ params?.mode == 'reg' ? '등록' : '상세보기 및 수정' }</h6></div>
                {params?.mode != 'reg' && (
                    <a href="#" className="comm-btn btn-md btn-gray bd-radius-3" 
                      onClick={(e) => { 
                        e.preventDefault()
                        setEditMode(!editMode)
                      }}
                    >
                      { !editMode ? '수정' : '보기' }
                    </a>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="form-col mb-15">
                <label htmlFor="active" className="lbl">노출여부 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int ms-50">
                  <select id="active" className="int" style={{ width: '128px' }}
                    onChange={(e) => {
                      setStory({ ...story, activeYn: e.target.value })
                    }} 
                    value={story?.activeYn ?? true}
                    disabled={!editMode}
                  >
                    <option value={ true } key={ true }>노출</option>
                    <option value={ false } key={ false }>미노출</option>
                  </select>
                </div>
              </div>
              <div className="form-col mb-15">
                <label htmlFor="best" className="lbl">Best Story 여부 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int ms-50">
                  <select id="best" className="int" style={{ width: '128px' }}
                    onChange={(e) => {
                      setStory({ ...story, bestYn: e.target.value })
                    }} 
                    value={story?.bestYn}
                    disabled={!editMode}
                  >
                    <option value={ true } key={ true }>설정</option>
                    <option value={ false } key={ false }>미설정</option>
                  </select>
                </div>
              </div>
              <div className="form-col mb-15">
                <label htmlFor="active" className="lbl">Best Youtube URL <span className="required"><span>필수입력</span></span></label>
                <div className="col-int ms-50">
                  <input
                      type="text"
                      id="link"
                      className="int"
                      placeholder="유튜브 URL"
                      value={story?.link}
                      style={{ width: '500px' }}
                      disabled={!editMode}
                      onChange={(e) => {
                        setStory({ ...story, link: e.target.value })
                      }} 
                    />
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl">대표 이미지 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int ms-50">
                  <div className="int-filebox">
                    <label htmlFor="m_fileName" className="comm-btn btn-secondary">파일 선택</label> 
                    <input className="upload-name int story" placeholder="파일을 선택하세요." disabled value={story?.mainImageFileName} />
                    <input type="file" id="m_fileName" className="upload-hidden" disabled={!editMode}
                      onChange={(e) => {
                        if(isEmpty(e.target.value)) {
                          saveExceptPreview('', 'main', '')
                        }else {
                          saveExceptPreview(e.target.files[0], 'main', e.target.value.split('/').pop().split('\\').pop())
                        }
                      }}
                    /> 
                  </div>
                  <p className="text-error">권장사이즈는 555 x 312 입니다.</p>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl"></label>
                <div className="col-int col-2">
                  { story?.mainImagePreview ? <img src={story?.mainImagePreview} alt="미리보기" /> : <div></div> }
                </div>
              </div>

              <div className="form-col mb-15">
                <label className="lbl">Best 이미지 <span className="required"><span>필수입력</span></span></label>
                <div className="col-int ms-50">
                  <div className="int-filebox">
                    <label htmlFor="b_fileName" className="comm-btn btn-secondary">파일 선택</label> 
                    <input className="upload-name int story" placeholder="파일을 선택하세요." disabaled value={story?.bestImageFileName} />
                    <input type="file" id="b_fileName" className="upload-hidden" disabled={!editMode}
                      onChange={(e) => {
                        if(isEmpty(e.target.value)) {
                          saveExceptPreview('', 'best', '')
                        }else {
                          saveExceptPreview(e.target.files[0], 'best', e.target.value.split('/').pop().split('\\').pop())
                        }
                      }}
                    /> 
                  </div>
                  <p className="text-error">권장사이즈는 555 x 312 입니다.</p>
                </div>
              </div>
              <div className="form-col mb-15">
                <label className="lbl"></label>
                <div className="col-int col-2">
                  { story?.bestImagePreview ? <img src={story?.bestImagePreview} alt="미리보기" /> : <div></div> }
                </div>
              </div>
              <div className="form-col mb-15">
                <label htmlFor="writer" className="lbl">작성자</label>
                <div className="col-int ms-50">
                  <input type="text" id="writer" className="int" placeholder="작성자" style={{ width: '150px' }} value={story?.writerName} disabled />
                </div>
              </div>
              <hr />
              <div className="form-col mb-15 justify-content-start">
                <label htmlFor="title" className="lbl">내용 <span className="required"><span>필수입력</span></span></label>
                <div className='internship__put'>
                  <div className='tit__box'>
                    <p className='tit'>
                      {/* 제목 */}
                      <textarea
                        type="text"
                        disabled={!editMode}
                        cols="30"
                        rows="5"
                        className="int int_textarea height_1"
                        placeholder="Title"
                        value={story?.title}
                        onChange={(e) => {
                          if (e.target.value.length < 1000) {
                            setStory({ ...story, title: e.target.value })
                          }
                        }}
                      />
                    </p>
                    <div className='date_t'>
                      <div className="d-flex align_items_center">
                        {/* 작성 날짜 및 기업명 */}
                        <span>
                          <input type="text" className="int" placeholder="Company Name" value={story?.companyName} disabled={!editMode}
                            style={{minWidth: "150px"}}
                            onChange={(e) => {
                              if(e.target.value.length <= 20) {
                                setStory({ ...story, companyName: e.target.value })
                              }
                          }} />
                        </span>
                        <span>
                          {/* {story?.updatedAt ?? setDateFormat() } */}
                          {setDateFormat(story?.updatedAt ?? story?.createdAt, 'YYYY.MM.DD HH:mm:ss') }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='visual_b'>
                    <div className='f_set'>
                      <div className='ico'>“</div>
                        <p>
                          {/* 인용구 */}
                          <textarea
                            type="text"
                            value={story?.quote}
                            disabled={!editMode}
                            cols="30"
                            rows="5"
                            className="int int_textarea height_1"
                            placeholder="Quote"
                            onChange={(e) => {
                              if (e.target.value.length < 1000) {
                                setStory({ ...story, quote: e.target.value })
                              }
                            }}
                          />
                        </p>
                      <div className='ico'>”</div>
                    </div>
                  </div>
                  <div className='info_box'>
                    <p className='stit'>
                      {/* 부 제목 */}
                      <input type="text" className="int" placeholder="Sub Title" value={story?.subTitle} disabled={!editMode}
                        onChange={(e) => {
                          if(e.target.value.length <= 150) {
                            setStory({ ...story, subTitle: e.target.value })
                          }
                      }} />
                    </p>
                    <p className='commp'>
                      {/* 요약 */}
                      <textarea
                        type="text"
                        value={story?.summary}
                        disabled={!editMode}
                        cols="30"
                        rows="10"
                        className="int int_textarea height_1"
                        placeholder="Summary"
                        onChange={(e) => {
                          if (e.target.value.length < 1000) {
                            setStory({ ...story, summary: e.target.value })
                          }
                        }}
                      />
                    </p>
                  </div>
                  <div className='chapter_box'>
                    <p className='chapter_tit'>
                      Chapter 1. <br />
                      {/* 챕터1 부 제목 */}
                      <input type="text" className="int" placeholder="Chapter1 Sub Title" value={story?.chapter1SubTitle} disabled={!editMode}
                        onChange={(e) => {
                          if(e.target.value.length <= 150) {
                            setStory({ ...story, chapter1SubTitle: e.target.value })
                          }
                      }} />
                    </p>
                    <p className='commp'>
                      <textarea
                        type="text"
                        value={story?.chapter1Content}
                        disabled={!editMode}
                        cols="30"
                        rows="10"
                        className="int int_textarea height_1"
                        placeholder="Chapter1 Content"
                        onChange={(e) => {
                          if (e.target.value.length < 1000) {
                            setStory({ ...story, chapter1Content: e.target.value })
                          }
                        }}
                      />
                    </p>
                    <div className='img_set'>
                      <div className='imgbox'>
                        <div className='img'>
                          <div className="int-filebox">
                            <div className="not-image">
                              {story?.chapter1ImagePreview 
                                ? ''
                                : <div><p>Chapter 1 이미지를 선택해주세요.</p><p className="text-error">권장사이즈는 680 x 412 입니다.</p></div>
                              }
                              <label htmlFor="chapter1_fileName"></label>
                            </div>
                            <div className="image-close-btn" style={{display: story?.chapter1ImagePreview ? 'block': 'none'}} onClick={() => {
                              if(editMode) {
                                setStory({ ...story, chapter1AddImage: [], chapter1ImagePreview: '', chapter1RemoveFiles: story?.chapter1Image.length > 0 ? [ ...story?.chapter1Image ] : [] })
                              }
                            }}></div>
                            { story?.chapter1ImagePreview 
                              ? <img className="preview" src={story?.chapter1ImagePreview} alt="미리보기" htmlFor="a_fileName" /> 
                              : <></>
                            }
                            <input type="file" id="chapter1_fileName" className="upload-hidden" disabled={!editMode} accept=".gif, .jpg, .png"
                              onChange={(e) => {
                                if(isEmpty(e.target.value)) {
                                  savePreview('', 1)
                                }else {
                                  savePreview(e.target.files[0], 1)
                                }
                              }}
                            /> 
                          </div>
                        </div>
                        <p>
                          <textarea
                            type="text"
                            value={story?.chapter1ImageQuote}
                            disabled={!editMode}
                            cols="30"
                            rows="5"
                            className="int int_textarea"
                            placeholder="Chapter1 Image Quote"
                            onChange={(e) => {
                              if (e.target.value.length < 1000) {
                                setStory({ ...story, chapter1ImageQuote: e.target.value})
                              }
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='chapter_box'>
                    <p className='chapter_tit'>
                      Chapter 2. <br />
                      {/* 챕터2 부 제목 */}
                      <input type="text" className="int" placeholder="Chapter2 Sub Title" value={story?.chapter2SubTitle} disabled={!editMode}
                        onChange={(e) => {
                          if(e.target.value.length <= 150) {
                            setStory({ ...story, chapter2SubTitle: e.target.value})
                          }
                      }} />
                    </p>
                    <p className='commp'>
                      <textarea
                        type="text"
                        value={story?.chapter2Content}
                        disabled={!editMode}
                        cols="30"
                        rows="10"
                        className="int int_textarea height_1"
                        placeholder="Chapter2 Content"
                        onChange={(e) => {
                          if (e.target.value.length < 1000) {
                            setStory({ ...story, chapter2Content: e.target.value})
                          }
                        }}
                      />
                    </p>
                    <div className='img_set'>
                      <div className='imgbox'>
                        <div className='img'>
                          <div className="int-filebox">
                            <div className="not-image">
                              {story?.chapter2ImagePreview 
                                ? ''
                                : <div><p>Chapter 2 이미지를 선택해주세요.</p><p className="text-error">권장사이즈는 680 x 412 입니다.</p></div>
                              }
                              <label htmlFor="chapter2_fileName"></label>
                            </div>
                            <div className="image-close-btn" style={{display: story?.chapter2ImagePreview ? 'block': 'none'}} onClick={() => {
                              if(editMode) {
                                setStory({ ...story, chapter2AddImage: [], chapter2ImagePreview: '', chapter2RemoveFiles: story?.chapter2Image.length > 0 ? [ ...story?.chapter2Image ] : []})
                              }
                            }}></div>
                            { story?.chapter2ImagePreview 
                              ? <img className="preview" src={story?.chapter2ImagePreview} alt="미리보기" htmlFor="a_fileName" /> 
                              : <></>
                            }
                            <input type="file" id="chapter2_fileName" className="upload-hidden" disabled={!editMode} accept=".gif, .jpg, .png"
                              onChange={(e) => {
                                if(isEmpty(e.target.value)) {
                                  savePreview('', 2)
                                }else {
                                  savePreview(e.target.files[0], 2)
                                }
                              }}
                            /> 
                          </div>
                        </div>
                        <p>
                          <textarea
                            type="text"
                            value={story?.chapter2ImageQuote}
                            disabled={!editMode}
                            cols="30"
                            rows="5"
                            className="int int_textarea"
                            placeholder="Chapter2 Image Quote"
                            onChange={(e) => {
                              if (e.target.value.length < 1000) {
                                setStory({ ...story, chapter2ImageQuote: e.target.value})
                              }
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='chapter_box'>
                    <p className='chapter_tit'>
                      Chapter 3. <br />
                      {/* 챕터3 부 제목 */}
                      <input type="text" className="int" placeholder="Chapter3 Sub Title" value={story?.chapter3SubTitle} disabled={!editMode}
                        onChange={(e) => {
                          if(e.target.value.length <= 150) {
                            setStory({ ...story, chapter3SubTitle: e.target.value})
                          }
                      }} />
                    </p>
                    <p className='commp'>
                      <textarea
                        type="text"
                        value={story?.chapter3Content}
                        disabled={!editMode}
                        cols="30"
                        rows="10"
                        className="int int_textarea height_1"
                        placeholder="Chapter3 Content"
                        onChange={(e) => {
                          if (e.target.value.length < 1000) {
                            setStory({ ...story, chapter3Content: e.target.value})
                          }
                        }}
                      />
                    </p>
                    <div className='img_set'>
                      <div className='imgbox'>
                        <div className='img'>
                          <div className="int-filebox">
                            <div className="not-image">
                              {story?.chapter3ImagePreview 
                                ? ''
                                : <div><p>Chapter 3 이미지를 선택해주세요.</p><p className="text-error">권장사이즈는 680 x 412 입니다.</p></div>
                              }
                              <label htmlFor="chapter3_fileName"></label>
                            </div>
                            <div className="image-close-btn" style={{display: story?.chapter3ImagePreview ? 'block': 'none'}} onClick={() => {
                              if(editMode) {
                                setStory({ ...story, chapter3AddImage: [], chapter3ImagePreview: '', chapter3RemoveFiles: story?.chapter3Image.length > 0 ? [ ...story?.chapter3Image ] : [] })
                              }
                            }}></div>
                            { story?.chapter3ImagePreview 
                              ? <img className="preview" src={story?.chapter3ImagePreview} alt="미리보기" htmlFor="a_fileName" /> 
                              : <></>
                            }
                            <input type="file" id="chapter3_fileName" className="upload-hidden" disabled={!editMode} accept=".gif, .jpg, .png"
                              onChange={(e) => {
                                if(isEmpty(e.target.value)) {
                                  savePreview('', 3)
                                }else {
                                  savePreview(e.target.files[0], 3)
                                }
                              }}
                            /> 
                          </div>
                        </div>
                        <p>
                          <textarea
                            type="text"
                            value={story?.chapter3ImageQuote}
                            disabled={!editMode}
                            cols="30"
                            rows="5"
                            className="int int_textarea"
                            placeholder="Chapter3 Image Quote"
                            onChange={(e) => {
                              if (e.target.value.length < 1000) {
                                setStory({ ...story, chapter3ImageQuote: e.target.value})
                              }
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            { params?.mode == 'reg' 
              ? (
                <div className="form-btn justify-content-center mt-30">
                  <div className="me-10">
                    <button type="submit" className="comm-btn bd-radius-5 btn-danger" 
                    onClick={() => cancelContents()} >취소</button>
                  </div>
                  <div>
                    <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                      onClick={() => insertContents()} >등록</button>
                  </div>
                </div>
              )
              : editMode 
              ? (
                <div className="form-btn justify-content-center mt-30">
                  <div className="me-10">
                    <button type="submit" className="comm-btn bd-radius-5 btn-danger" 
                    onClick={() => cancelContents()} >취소</button>
                  </div>
                  <div>
                    <button type="submit" className="comm-btn bd-radius-5 btn-gray" 
                      onClick={() => updateContents()} >수정</button>
                  </div>
                </div>
              )
              : (
                <div className="form-btn justify-content-center mt-30">
                  <Link to={`/contents/internship/story`}>
                    <button type="submit" className="comm-btn bd-radius-5 btn-gray"
                      onClick={() => {}} >목록</button>
                  </Link>
                </div>
              )
            }
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default InternshipStory
