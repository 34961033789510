import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import SideMenu from '../sidemenu/index'
import { MENUITEMS } from '../../data/menu'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../api/auth'
import { delToken } from '../../redux/token'
import { delUser } from '../../redux/user'
import { useAppDispatch } from '../../redux/store'
import store from '../../redux/store'

const Header = (props) => {
  const history = useNavigate()
  const dispatch = useAppDispatch()

  const path = window.location.pathname.split('/')
  const [selMenu, setSelMenu] = useState(-1)

  const removeUser = async () => {
    await logout()
    dispatch(delToken())
    dispatch(delUser())
    history('/login')
  }

  return (
    <Fragment>
      <header id="header">
        <SideMenu />
        <div className="header-toolbar">
          <nav className="header-gnb">
            <button
              type="button"
              className="btn-header-menu"
              onClick={() => {
                props.setFold(!props.fold)
              }}
            >
              <span className="blind">사이드 메뉴 열고 닫기</span>
            </button>
            <ul className="gnb-menu">
              {MENUITEMS.Items.map((menu, idx) => (
                <li
                  key={idx}
                  className={
                    (menu.path.includes(path[1]) ? 'on' : '') +
                    (selMenu === idx ? ' ov' : '')
                  }
                  onMouseOver={() => setSelMenu(idx)}
                  onMouseOut={() => setSelMenu(-1)}
                >
                  {menu.type === 'sub' ? (
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <span className="menu-icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d={menu.icon} fill="#1D1D1D" />
                        </svg>
                      </span>
                      <span className="menu-text">{menu.title}</span>
                    </a>
                  ) : (
                    <Link to={menu.path}>
                      <span className="menu-icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d={menu.icon} fill="#1D1D1D" />
                        </svg>
                      </span>
                      <span className="menu-text">{menu.title}</span>
                    </Link>
                  )}

                  {menu.children.length > 0 && (
                    <div
                      className="gnb-menu-sub"
                      style={{ display: selMenu === idx ? 'block' : 'none' }}
                    >
                      <ul>
                        {menu.children.map((ch, idx1) => (
                          <li key={idx1}>
                            <Link to={ch.path}>{ch.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="header-right">
            <Link className="profile-name" to={`/mypage`}>
              <span className="img-user">
                <img
                  src={require('../../assets/images/common/img_user.png')}
                  alt="내사진"
                />
              </span>{' '}
              {store.getState().user.username}님
            </Link>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                removeUser()
              }}
              className="header-logout"
            >
              로그아웃 <i className="ico_out"></i>
            </a>
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default Header
